import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../../CommonComponent/Loader';
import { getSubjectData } from '../../../Exam/Services/ExamReportAPIURL';
import { printSubWiseEndowmentReportAPIURL, printSubWiseSchoolEndowmentReportAPIURL } from '../Services/EndowmentReportAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import { getSubjectEndowment } from '../Services/EndowmentSubjectAPIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const SubjectWiseEndowmentReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "SubjectWise Endowment Report";

    const [loaderOption, setLoaderOption] = useState(false);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [subjectType, setSubjectType] = useState('');

    // useEffect(() => {
    //     getData();
    // }, [])


    // const getData = async () => {
    //     await axios.get(`${getSubjectData}`)
    //         .then((res) => {
    //             setSubjectData(res.data);
    //         }).catch(error => {
    //             console.log(error);
    //         })
    // }

    const getSubjectListById = async (sId) => {
        if (sId == 1) {
            await axios.get(`${getSubjectData}`)
                .then((res) => {
                    setSubjectData(res.data);
                    //setSubjectObj(res.data[0])
                }).catch(error => {
                    console.log(error);
                })
        }
        else if (sId == 2) {
            await axios.get(`${getSubjectEndowment}`)
                .then((res) => {
                    setSubjectData(res.data);
                    //setSubjectObj(res.data[0])
                }).catch(error => {
                    console.log(error);
                })
        }
        else {
            setSubjectData([]);
        }
    }

    const getSubjectWiseEndowment = async () => {
        setLoaderOption(true);
        if (authUser.branchId == 50) {
            await axios.get(`${printSubWiseEndowmentReportAPIURL}?sessionYear=${authUser.sessionYear}&subType=${subjectType}&subId=${subjectObj.id}`)
                .then(res => {
                    if (res.data === "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Endowment is not found for this subject.")
                    }
                    else if (res.data != null) {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(error => {
                    console.log(error);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            await axios.get(`${printSubWiseSchoolEndowmentReportAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&sessionYear=${authUser.sessionYear}&subType=${subjectType}&subId=${subjectObj.id}`)
                .then(res => {
                    if (res.data === "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Endowment is not found for this subject.")
                    }
                    else if (res.data != null) {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(error => {
                    console.log(error);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Subject Type :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth
                                        margin="dense">
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Subject Type</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={subjectType}
                                            label="Subject Type"
                                            onChange={(e) => {
                                                setSubjectType(e.target.value);
                                                setSubjectObj(null);
                                                getSubjectListById(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={1}>Academic</MenuItem>
                                            <MenuItem value={2}>Non-Academic</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row my-1">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Subject :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={subjectData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        value={subjectObj}
                                        onChange={(event, newValue) => {
                                            setSubjectObj(newValue);
                                        }}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Subject" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12 offset-sm-2">
                                    <button type="button" onClick={() => getSubjectWiseEndowment()} disabled={(subjectObj == null || subjectObj == "" || subjectType == '') ? true : false} className="btn btn-primary btn-sm">Show</button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default SubjectWiseEndowmentReport