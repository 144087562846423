import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { BaseURLAccountMaster } from '../../CommonServices/AccountMasterAPIURL';
import { getExportedReportAPIURL } from "../../CommonServices/APIURL";
import { printAccountLedgerReportAPIURL } from "../Services/AccountMasterReportAPI";
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const AccountLedger = () => {
    const titleId = "Account Ledger Report";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    //const navigate = useNavigate();

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);
    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/03/31`);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [accountData, setAccountData] = useState([]);
    const [accCodeObj, setAccCodeObj] = useState(null);
    const [id, setId] = useState();
    const [dateCheckFrom, setDateCheckFrom] = useState("");
    const [dateCheckTo, setDateCheckTo] = useState("");
    const [option, setOption] = useState(1);
    const [languageId, setLanguageId] = useState(1);

    const defaultFromDate = new Date(authUser.sessionYear, 3, 1);
    const defaultToDate = new Date(parseInt(authUser.sessionYear) + 1, 2, 31);

    useEffect(() => {
        if (authUser.sessionYear != '') {
            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
        }
    }, [authUser])


    useEffect(() => {
        getAccountData();
    }, [authUser]);


    const getAccountData = async () => {
        //console.log(BaseURLAccountMaster + authUser.deptId);
        if (authUser.deptId != null && authUser.sessionYear != '' && authUser.branchId != null) {
            await axios(`${BaseURLAccountMaster}?sid=${authUser.deptId}&acyr=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then((response) => {
                    setAccountData((response.data).sort((a, b) => a.accountCode > b.accountCode ? 1 : -1));
                }).catch((error) => {
                    console.log(error);
                })
        }

        // const maxDate = new Date(authUser.sessionYear + 1, 2, 31);
        // maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
        // if (dateFrom < defaultMinDate || dateFrom >= maxDate) {
        //     setDateCheckFrom("Invalid date");
        // } else {
        //     setDateCheckFrom("");
        // }

        // if (dateTo < defaultMinDate || dateTo >= maxDate) {
        //     setDateCheckTo("Invalid date");
        // } else {
        //     setDateCheckTo("");
        // }

    }

    const getAccLedgerReport = async (e) => {
        e.preventDefault();

        if (moment(dateFrom).format("DD/MM/YYYY") !== "Invalid date" && moment(dateTo).format("DD/MM/YYYY") !== "Invalid date" && dateCheckTo !== "Invalid date" && dateCheckFrom !== "Invalid date") {
            setLoaderOption(true);
            //?fromDate=14/01/2021&toDate=24/06/2022&sid=4&bid=1&acCode=1202&acYear=2021
            //console.log(`${printAccountLedgerReportAPIURL}?fromDate=${moment(dateFrom).format("DD/MM/YYYY")}&toDate=${moment(dateTo).format("DD/MM/YYYY")}&sid=${authUser.deptId}&bid=${authUser.branchId}&acCode=${accCodeObj.accountCode}&acYear=${authUser.sessionYear}&deptName=${authUser.deptName}&srNoBy=${option}`)
            await axios.get(`${printAccountLedgerReportAPIURL}?fromDate=${moment(dateFrom).format("DD/MM/YYYY")}&toDate=${moment(dateTo).format("DD/MM/YYYY")}&sid=${authUser.deptId}&bid=${authUser.branchId}&acCode=${accCodeObj.accountCode}&acYear=${authUser.sessionYear}&deptName=${authUser.deptName}&srNoBy=${option}&langId=${languageId}`)
                .then((response) => {

                    if (response.data == "NOTFOUND") {
                        //console.log(response.data)    
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else if (response.data != null) {
                        // setDateFrom(todaysDate);
                        // setDateTo(todaysDate);
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }
                })
                .catch(error => {

                    toast.error("Error Occoured, Try after sometime.");

                })
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.");
        }
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={getAccLedgerReport}>
                        <div className="form-data">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                            <div className='row'>
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Language :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Language</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth

                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={languageId}
                                            label="Select Language"
                                            onChange={(e) => {
                                                setLanguageId(e.target.value);
                                                setAccCodeObj(null);
                                            }}
                                        >
                                            <MenuItem value={1}>Marathi</MenuItem>
                                            <MenuItem value={2}>English</MenuItem>
                                        </Select>
                                    </FormControl>

                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Account :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={accountData}
                                        value={accCodeObj}
                                        onChange={(event, newvalue) => {
                                            setAccCodeObj(newvalue);
                                        }}
                                        style={{ fontSize: '14px' }}
                                        //getOptionLabel={option => (authUser.branchMedium == 1) ? option.accountCode + " - " + option.accountNameMr : option.accountCode + " - " + option.accountNameEn}
                                        getOptionLabel={option => option.accountCode + " - " + option.accountNameMr}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Account" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date From"
                                            value={dateFrom}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckFrom("Invalid date") : setDateCheckFrom("")
                                                const maxDate = new Date(defaultMaxDate);
                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                    setDateCheckFrom("Invalid date");
                                                } else {
                                                    setDateCheckFrom("");
                                                }
                                            }}
                                            disableFuture={true}
                                            disableHighlightToday={true}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    margin='dense'
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                            openTo="day"
                                            // Show defaultMaxDate selected when the DatePicker is opened
                                            // If dateFrom is not set, defaultMaxDate is used
                                            onOpen={() => setDateFrom(dateFrom || defaultMaxDate)}
                                        />
                                    </LocalizationProvider>
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date To :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date To"
                                            value={dateTo}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateTo(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo("")
                                                const maxDate = new Date(defaultMaxDate);
                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                    setDateCheckTo("Invalid date");
                                                } else {
                                                    setDateCheckTo("");
                                                }
                                            }}
                                            disableFuture={true}
                                            disableHighlightToday={true}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    margin='dense'
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                            openTo="day"
                                            // Show defaultMaxDate selected when the DatePicker is opened
                                            // If dateFrom is not set, defaultMaxDate is used
                                            onOpen={() => setDateTo(dateTo || defaultMaxDate)}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Order By :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin="dense">
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Select Order By</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={option}
                                            label="Select Order By"
                                            onChange={(e) => {
                                                setOption(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={1}>Date Wise</MenuItem>
                                            <MenuItem value={2}>Voucher No. Wise</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12 offset-sm-2">
                                    <button type="submit"
                                        className="btn btn-primary btn-sm"
                                    >Show
                                    </button>

                                    <button type="button"
                                        className="btn btn-danger btn-sm"
                                        style={{ margin: '0px 4px' }}
                                        onClick={() => { setDateTo(null); setDateFrom(null); setAccCodeObj(null); }}
                                    >Cancel
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }



};

export default AccountLedger;
