import { BaseURL } from "../../CommonServices/APIURL";


//POST
// http://192.168.29.102:8081/institute-sss/institute/sss/service/serviceForm/saveToggleAllocationToRole
// {
//     "roleId": 1,
//     "toggleIds": "1,2"
// }


// Resp :: SAVED
export const saveToggleToRoleAllocAPIURL = BaseURL + "service/serviceForm/saveToggleAllocationToRole";

//http://192.168.29.102:8081/institute-sss/institute/sss/service/serviceForm/updateToggleAllocationToRole
export const updateToggleToRoleAllocAPIURL = BaseURL + "service/serviceForm/updateToggleAllocationToRole";

//http://192.168.29.102:8081/institute-sss/institute/sss/service/serviceForm/deleteToggleAllocationToRole?id=2
export const deleteToggleToRoleAllocAPIURL = BaseURL + "service/serviceForm/deleteToggleAllocationToRole";

export const getToggleToRoleAllocAPIURL = BaseURL + "service/serviceForm/getAllToggleAllocationToRole";



//http://192.168.29.102:8081/institute-sss/institute/sss/service/serviceForm/getToggleAllocationToRole?roleId=1&bid=3
export const getToggleToRoleBranchWiseAPIURL = BaseURL + "service/serviceForm/getToggleAllocationToRole";

//http://192.168.29.102:8081/institute-sss/institute/sss/service/serviceForm/saveToggleAllocationToRole
export const saveToggleToRoleBranchWiseAPIURL = BaseURL + "service/serviceForm/saveToggleAllocationToRole";

//getToggleAllocationToRoleByRoleId
export const getToggleToRoleBranchWiseDetailsAPIURL = BaseURL + "service/serviceForm/getToggleAllocationToRoleByRoleId";





