import React from 'react'
import { Route, Routes } from 'react-router-dom';
import ServiceDashboard from './ServiceDashboard'
import ServiceModuleMaster from './ServiceModuleMaster';
import ServiceFormMaster from './ServiceFormMaster';
import AdminErrorHandlingForm from './AdminErrorHandlingForm';
import SystemUpdate from './SystemUpdate';
import FormProcessing from './FormProcessing';
import TogglemasterForm from './TogglemasterForm';
import ToggleToRoleForm from './ToggleToRoleForm';
import ToggleToRoleBranchWise from './ToggleToRoleBranchWise';


const ServiceContent = () => {
  return (
    <div className="container my-4 py-1">
      <Routes>
        <Route exact path="/d001/" element={<ServiceDashboard />} />
        <Route exact path="/m001/" element={<ServiceModuleMaster />} />
        <Route exact path="/m002/" element={<ServiceFormMaster />} />
        <Route exact path="/m003/" element={<TogglemasterForm />} />
        <Route exact path="/a001/:statusType" element={<AdminErrorHandlingForm />} />
        <Route exact path="/su001/" element={<SystemUpdate />} />
        <Route exact path="/st001/" element={<ToggleToRoleBranchWise />} />
        <Route exact path="/fp001/" element={<FormProcessing />} />
      </Routes>
    </div>
  )
}

export default ServiceContent