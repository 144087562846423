import React, { useEffect, useState } from 'react'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import TitleLabel from '../../CommonComponent/TitleLabel';
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';

import {
    deleteFormDataAPIURL,
    getAllFormDataAPIURL,
    updateFormDataAPIURL,
    saveFormDataAPIURL
} from '../Service/FormMasterAPIURL';
import { getAllModuleDataAPIURL } from '../Service/ModuleMasterAPIURL'
import { getToggleMasterAPIURL } from '../Service/ToggleMasterAPIURL';
import { getUserRolesAPIURL } from '../../Sanstha/Services/SansthaAPIURL';
import { deleteToggleToRoleAllocAPIURL, getToggleToRoleAllocAPIURL, saveToggleToRoleAllocAPIURL, updateToggleToRoleAllocAPIURL } from '../Service/ToggleToRoleAPIURL';
import Loader from '../../CommonComponent/Loader';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const ToggleToRoleForm = () => {

    //Table Data

    const classes = useStyles();
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [loaderOption, setLoaderOption] = useState(false);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }



    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(true);

    //Form Values    
    const [id, setId] = useState("");
    const [roleObj, setRoleObj] = useState(null);
    const [toggleId, setToggleId] = useState([]);
    const [toggleObj, setToggleObj] = useState([]);
    const [deleteId, setDeleteId] = useState("");



    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `User Name `, key: 'mname' },
        { label: `Toggle Name `, key: 'fname' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }
    ];

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [toggleData, setToggleData] = useState([]);


    useEffect(() => {
        getData();

    }, [])

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.toggleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.roleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const getData = async () => {
        await axios.get(getToggleToRoleAllocAPIURL)
            .then((res) => {
                if (res.data !== null) {
                    setData(res.data);
                    setFilteredData(res.data);
                }
            })
            .catch((err) => {
                console.log(err)
            })

        await axios.get(getToggleMasterAPIURL)
            .then((res) => {
                if (res.data !== null) {
                    setToggleData(res.data);
                }
            })
            .catch((err) => {
                console.log(err)
            })

        await axios.get(getUserRolesAPIURL)
            .then((response) => {
                if (response.data !== null) {
                    let newData = response.data;
                    // let updatedData = [{ "id": 0, "name": "All" }, ...newData]
                    setRoleData(newData);
                    // setLoaderOption(false);
                    //console.log(JSON.stringify(updatedData))
                }
            })
            .catch((error) => {
                console.log(error);
                // setLoaderOption(false);
            })
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "roleId": roleObj.id,
            "toggleIds": toggleId
        }
        console.log(JSON.stringify(saveObj))
        await axios.post(saveToggleToRoleAllocAPIURL, saveObj)
            .then((res) => {
                if (res.data === "SAVED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved Successfully")
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("This Data is already exists.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong")
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                console.log(err)
            })
    };

    const updateBtnClick = async () => {
        setLoaderOption(true);

        const updateObj = {
            "id": id,
            "roleId": roleObj.id,
            "toggleIds": toggleId
        }
        await axios.put(updateToggleToRoleAllocAPIURL, updateObj)
            .then((res) => {
                if (res.data === "UPDATED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated Sucessfully")
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("This Data is already exists.")
                }
                else {
                    setLoaderOption(false);
                    toast.success("Something went wrong")
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                console.log(err)
            })
    };

    const DeleteData = async (id) => {
        setLoaderOption(true);

        await axios.delete(`${deleteToggleToRoleAllocAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data === "DELETED") {
                    getData();
                    setLoaderOption(false);
                    toast.success("Deleted sucessfully");
                }
            });

    }

    const ClearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        setId("");
        setRoleObj(null);
        setToggleObj([]);
        setToggleId([]);
    }

    const UpdateData = (item) => {
        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);
        setSaveBtnDisabled(true);
        setUpdateBtnDisabled(false);
        setId(item.id);
        setRoleObj(roleData.find(e => e.id == item.roleId))

        setToggleObj(item.toggleMasters);

        // Set the school IDs by mapping over toggleMasters and joining them as a comma-separated string
        setToggleId(item.toggleMasters.map(e => e.id).join(','));

        // setFormName(item.formName);
        // setToggleObj(moduleData.find(e => e.id === item.moduleId));

    }


    if (loaderOption == true) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <>
                <TitleLabel titleId="Toggle To User" />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 20px #ABB2B9", marginTop: "16px", minHeight: "200px" }}>

                    <form onSubmit={onSubmit}>
                        <div className="form-data mt-1">

                            <div className="row">
                                <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>User :</label>
                                <div className="col-sm-3">
                                    <Autocomplete
                                        value={roleObj}
                                        id="combo-box-demo"
                                        options={roleData}
                                        onChange={(event, newValue) => {
                                            setRoleObj(newValue)
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.name}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select User" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>

                                <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Toggle :</label>
                                <div className="col-sm-4">
                                    <Autocomplete
                                        value={toggleObj}
                                        id="combo-box-demo"
                                        multiple
                                        disableCloseOnSelect
                                        filterSelectedOptions
                                        options={toggleData}
                                        onChange={(event, newValue) => {
                                            setToggleObj(newValue)
                                            setToggleId(newValue.map(e => e.id).join(','))
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.nameMr}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Toggle" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                required={(toggleObj.length == 0) ? true : false} />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 mt-3">
                                <div className="offset-sm-1">
                                    <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                    <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                    <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>

                                </div>
                            </div>
                        </div>
                    </form>
                    <hr />


                    <div className="col-sm-12">
                        <div className='row mt-1'>
                            <div >
                                <input
                                    className='form-control form-control-sm'
                                    type="text"
                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                    placeholder="Search Here"
                                    value={searchTerm}
                                    onChange={(event) => { setSearchTerm(event.target.value); }}
                                />
                            </div>
                        </div>

                        <div className='table-responsive'>
                            <table className="table table-bordered">
                                <thead className="table-Default">
                                    <tr>
                                        {TableHeading.map((item, index) => {
                                            return (
                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            // Extract school names directly from the schoolDataList array
                                            const toggleNames = item.toggleMasters.map(school => school.nameMr);
                                            const colors = ["#6C3483", "#2874A6", "#A93226", "#1ABC9C", "#F39C12", "#D35400"];
                                            return (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.id}</td>

                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.roleName}</td>

                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }}>
                                                            {
                                                                toggleNames.map((schoolName, i) => (
                                                                    <span
                                                                        className='badge'
                                                                        key={i}
                                                                        style={{
                                                                            backgroundColor: colors[i % colors.length], // Use modulo to cycle through colors
                                                                            borderRadius: "10px",
                                                                            fontWeight: "600",
                                                                            fontSize: "12px",
                                                                            margin: "4px",
                                                                            padding: "6px"
                                                                        }}
                                                                    >
                                                                        {schoolName}
                                                                    </span>
                                                                ))
                                                            }
                                                        </td>
                                                        <td>
                                                            <Tooltip title="Edit">
                                                                <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                    onClick={() => UpdateData(item)} data-bs-toggle="tooltip">
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </span>
                                                            </Tooltip>
                                                        </td>
                                                        <td>
                                                            <Tooltip title="Delete">
                                                                <span className='btn btn-danger btn-sm mx-1 p-1'
                                                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                    onClick={() => setDeleteId(item.id)}                                                >
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </span>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        })}
                                </tbody>
                                <tfoot style={{ padding: "0px" }}>
                                    <tr>
                                        <TablePagination
                                            style={{ padding: "0px" }}
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onRowsPerPageChange={onRowsPerPageChange}
                                            onPageChange={onPageChange}
                                            labelRowsPerPage="Rows Per Page :"
                                            classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>
                <ToastContainer position="top-right" theme="colored" />
                <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
            </>
        )
    }

}


export default ToggleToRoleForm
