import { BaseURL } from "../../CommonServices/APIURL";

//POST
// http://192.168.29.102:8081/institute-sss/institute/sss/service/serviceForm/saveToggleMaster

export const saveToggleMasterAPIURL = BaseURL + "service/serviceForm/saveToggleMaster";

//http://192.168.29.102:8081/institute-sss/institute/sss/service/serviceForm/updateToggleMaster
export const updateToggleMasterAPIURL = BaseURL + "service/serviceForm/updateToggleMaster";

//http://192.168.29.102:8081/institute-sss/institute/sss/service/serviceForm/getAllToggleMaster
export const getToggleMasterAPIURL = BaseURL + "service/serviceForm/getAllToggleMaster";


//http://192.168.29.102:8081/institute-sss/institute/sss/service/serviceForm/deleteToggleMaster?id=3
export const deleteToggleMasterAPIURL = BaseURL + "service/serviceForm/deleteToggleMaster";

