import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

//const BaseURL="http://192.168.1.102:8080/institute/sss/"

export const ServiceBaseURL = BaseURL + "service/"

export const getNoticeDataAPIURL = ServiceBaseURL + "getNoticeData"

export const saveNoticeDataAPIURL = ServiceBaseURL + "saveNoticeData"


//http://192.168.195.102:8081/institute-sss/institute/sss/service/serviceForm/getToggleAllocationToRoleByRoleId?roleId=1
export const getToggleDataAPIURL = BaseURL + "service/serviceForm/getToggleAllocationToRoleByRoleId";

// /institute/sss/service/

// PostMapping /saveNoticeData

// {
// 	"id":1,
// 	"remark":"Update 30/09/2022",
// 	"status":1
// }

// ---------------------------------

// GetMapping

// /getNoticeData