import { dBaseURL, BaseURL } from "../../CommonServices/APIURL";

//localhost:8080/institute/sss/administration/reports/getStudentIdCardReport?langId=1&classId=5&divId=17&sessionYear=2022&sid=3&bid=1
export const printStudentIdcardClassDivWise = BaseURL + "administration/reports/getStudentIdCardReport";

//getStudentIdCardReportExcel
export const printStudentIdcardClassDivWiseExcelReport = BaseURL + "administration/reports/getStudentIdCardReportExcel";


//localhost:8080/institute/sss/administration/reports/getStudentIdCardReportByRegNo?langId=1&sessionYear=2022&sid=3&bid=1&regNo=12707
export const printStudentIdcardRegNo = BaseURL + "administration/reports/getStudentIdCardReportByRegNo";

export const printStudentIdcardRegNoExcelReport = BaseURL + "administration/reports/getStudentIdCardReportExcelByRegNo";


