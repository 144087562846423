import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../../CommonComponent/Loader';
import { getSchoolDataAPIURL } from '../../../Administration/Services/TransferStudentsAPIURL';
import { printAllSchoolWiseEndowmentWiseReport, printSchoolWiseEndowmentWiseReport, printSchoolWiseEndowmentWiseReportForSchool } from '../Services/EndowmentReportAPIURL';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import { getSchoolListForEndowment } from '../Services/EndowmentMasterAPIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  select: {
    maxHeight: 160,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  },

}));

const SchoolWiseEndowWiseDetailReport = () => {
  const classes = useStyles();
  const authUser = useSelector((state) => state.user.value);
  const titleId = "Schoolwise Endowmentwise Detail Report";

  const [loaderOption, setLoaderOption] = useState(false);
  const [optionId, setOptionId] = useState(1);
  const [schoolData, setSchoolData] = useState([]);
  const [schoolObj, setSchoolObj] = useState(null);
  const [year, setYear] = useState(null);
  const [endowmentId, setEndowmentId] = useState('');

  useEffect(() => {
    getData();
  }, [])


  const getData = async () => {
    await axios.get(`${getSchoolListForEndowment}`)
      .then(res => {
        setSchoolData(res.data);
      })
  }

  const getAllSchoolWiseEndowmentWiseReport = async () => {
    setLoaderOption(true);
    //console.log(`${generateSubjectAllocationReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
    if (authUser.branchId == 50) {
      await axios.get(`${printAllSchoolWiseEndowmentWiseReport}?sessionYear=${moment(year).format("YYYY")}`)
        .then((response) => {
          if (response.data == "NOTFOUND") {
            //console.log(response.data)  
            setLoaderOption(false);
            toast.warn("Data not found.")
          }
          else if (response.data != null) {
            //console.log(response.data)                    
            window.open(`${getExportedReportAPIURL}${response.data}`);
            setLoaderOption(false);
          }
        })
        .catch(error => {
          toast.error("Something went wrong, try after sometime.")
          console.log("Endowment Report failed: " + error)
        })
    }
    else {
      await axios.get(`${printSchoolWiseEndowmentWiseReportForSchool}?sid=${authUser.deptId}&bid=${authUser.branchId}&sessionYear=${moment(year).format("YYYY")}`)
        .then((response) => {
          if (response.data == "NOTFOUND") {
            //console.log(response.data)  
            setLoaderOption(false);
            toast.warn("Data not found.")
          }
          else if (response.data != null) {
            //console.log(response.data)                    
            window.open(`${getExportedReportAPIURL}${response.data}`);
            setLoaderOption(false);
          }
        })
        .catch(error => {
          toast.error("Something went wrong, try after sometime.")
          console.log("Endowment Report failed: " + error)
        })
    }
  }

  const getSchoolWiseEndowmentWiseReport = async () => {
    setLoaderOption(true);
    //console.log(`${generateSubjectAllocationReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
    await axios.get(`${printSchoolWiseEndowmentWiseReport}?sessionYear=${moment(year).format("YYYY")}&endowmentId=${endowmentId}`)
      .then((response) => {
        if (response.data == "NOTFOUND") {
          //console.log(response.data)  
          setLoaderOption(false);
          toast.warn("Data not found with this prize code.")
        }
        else if (response.data != null) {
          //console.log(response.data)                    
          window.open(`${getExportedReportAPIURL}${response.data}`);
          setLoaderOption(false);
        }
      })
      .catch(error => {
        toast.error("Something went wrong, try after sometime.")
        console.log("Endowment Report failed: " + error)
      })
  }


  const printSchoolWiseEndowment = (e) => {
    e.preventDefault();
    if (optionId == 1) {
      getAllSchoolWiseEndowmentWiseReport();
    }
    else if (optionId == 2) {
      getSchoolWiseEndowmentWiseReport();
    }
  }



  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <div>
            <TitleLabel titleId={titleId} />

            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '300px' }}>
              <form onSubmit={printSchoolWiseEndowment}>
                <div className='row my-2' hidden={(authUser.branchId == 50) ? false : true}>
                  <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>Report Option:</label>
                  <div className='col-sm-3'>
                    <FormControl fullWidth >
                      <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Report Option</InputLabel>
                      <Select
                        MenuProps={{ classes: { paper: classes.select } }}
                        size='small'
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={optionId}
                        margin='dense'
                        label="Select Report Option"
                        onChange={(e) => {
                          setOptionId(e.target.value);
                          setYear(null);
                          setSchoolObj(null);
                        }}
                      >
                        <MenuItem value={1}>All</MenuItem>
                        <MenuItem value={2}>School Wise</MenuItem>
                      </Select>
                    </FormControl>

                  </div>
                </div>

                <div className="row">
                  <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Year :</label>
                  <div className='col-sm-3'>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Year"
                        value={year}
                        inputFormat="yyyy"
                        views={['year']}
                        onChange={(newValue) => {
                          setYear(newValue);
                          setSchoolObj(null);
                          // (newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo(null)
                        }}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            variant="outlined"
                            margin='dense'
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            onKeyDown={(e) => e.preventDefault()}
                            style={{
                              caretColor: "transparent",
                            }}
                            required
                            fullWidth
                            autoComplete='off'
                            size="small" />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                {(() => {
                  if (optionId == 2) {
                    return (
                      <>
                        <div className="row my-2" hidden={(authUser.branchId == 50) ? false : true}>
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School:</label>
                          <div className='col-sm-4'>
                            <Autocomplete
                              id="combo-box-demo"
                              options={schoolData}
                              PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                              )}
                              value={schoolObj}
                              onChange={(event, newValue) => {
                                setSchoolObj(newValue);
                                if (newValue !== null) {
                                  setEndowmentId(newValue.endowmentId);
                                }
                              }}
                              style={{ fontSize: '14px' }}
                              getOptionLabel={option => option.endowmentId + " . " + option.schoolName}
                              size="small"
                              renderInput={params => (
                                <TextField {...params} margin='dense' label="Select School" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )
                  }
                })()}

                <div className="row mt-3">
                  <div className="col-sm-12 offset-sm-2">
                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

      }
      <ToastContainer position="top-right" theme="colored" />
    </>
  )
}


export default SchoolWiseEndowWiseDetailReport