import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import PrintIcon from '@mui/icons-material/Print';
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { getMasterReportAPIURL } from '../Services/MasterReportAPIURL';
import Loader from '../../CommonComponent/Loader';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getEmployeeBioReportAPIURL } from '../Services/EmployeeReportAPIURL';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';


const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
}));

const EmployeeBioReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Employee Biometric Report";
    const [schoolData, setSchoolData] = useState([]);
    const [schoolObj, setSchoolObj] = useState(null);
    const [option, setOption] = useState(1);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        setLoaderOption(true);
        await axios.get(`${BaseURLInstituteMaster}`)
            .then(res => {
                setSchoolData(res.data);
                setLoaderOption(false);
            }).catch(err => {
                setLoaderOption(false);
                console.log("school get err" + err);
            })
    }


    const printEmpBioForSchoolAndSansthaReport = (e) => {
        e.preventDefault();
       
        setLoaderOption(true);
        axios.get(`${getEmployeeBioReportAPIURL}?option=${(authUser.branchId == 50) ? option : 0}&langId=${authUser.branchMedium}&bid=${(authUser.branchId == 50 && option == 2) ? schoolObj.id : authUser.branchId}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    //console.log(response.data)  
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("employee bio Report failed: " + error)
            })
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={printEmpBioForSchoolAndSansthaReport}>
                        {(() => {
                            if (authUser.branchId == 50) {
                                return (
                                    <>
                                        <div className="row">
                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                            <div className="col-sm-3">
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px', marginBottom: "50px" }}>Option</InputLabel>
                                                    <Select
                                                        MenuProps={{ classes: { paper: classes.select } }}
                                                        size='small'
                                                        fullWidth
                                                        required={(authUser.branchId == 50) ? true : false}
                                                        //inputProps={{ readOnly: true }}
                                                        margin="dense"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={option}
                                                        label="Option"
                                                        onChange={(e) => {
                                                            setOption(e.target.value);
                                                            setSchoolObj(null);
                                                        }}
                                                    >
                                                        <MenuItem value={1}>All</MenuItem>
                                                        <MenuItem value={2}>School Wise</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>


                                        {(() => {
                                            if (option == 2) {
                                                return (
                                                    <>
                                                        <div className='row my-3'>
                                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "550", marginTop: "10px" }}>School :</label>
                                                            <div className='col-sm-4'>
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    //disableClearable

                                                                    options={schoolData}
                                                                    style={{ fontSize: '14px' }}
                                                                    PaperComponent={({ children }) => (
                                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                    )}
                                                                    onChange={(event, item) => {
                                                                        setSchoolObj(item)
                                                                    }}
                                                                    value={schoolObj}

                                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn}
                                                                    size="small"
                                                                    renderInput={params => (
                                                                        <TextField {...params} inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select School" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(authUser.branchId == 50) ? true : false} />
                                                                    )}
                                                                />

                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        })()}
                                    </>
                                )
                            }
                        })()}

                        <div className={(authUser.branchId == 50) ? "row mt-3" : 'row'}>
                            <div className={(authUser.branchId == 50) ? "col-sm-4 offset-sm-1" : "col-sm-4"}>
                                <button
                                    type='submit'
                                    className='btn btn-primary btn-sm mb-2'
                                >
                                    <PrintIcon fontSize="small" /> Print
                                </button>
                            </div>
                        </div>
                    </form >
                </div >
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>

        )
    }


}

export default EmployeeBioReport
