import React, { useState, useEffect } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TechnoAarvLogo from '../../assets/img/technoAarvLogoW.png';
import axios from 'axios';
import { serviceLogin, serviceLogout, userNotification } from '../../reduxFeature/user';
import { useNavigate } from "react-router";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import useSound from 'use-sound';
import boopSfx from '../../assets/sound/notification.mp3';
import { getServiceFormAdminFlagDataCountAPIURL } from '../Service/allEnquiryAPIURL';

// import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap'
import FormProcessingModal from './FormProcessingModal';


const ServiceHeader = () => {
    const authUser = useSelector((state) => state.user.value);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    //console.log(authUser)
    const [play] = useSound(boopSfx, { interrupt: true });

    const [showModal, setShowModal] = useState(false);

    const handleShow = () => { setShowModal(true); navigate('/service/s001/d001/') };

    const navbg = {
        background: "#7f53ac",
        background: "-webkit-linear-gradient(315deg, #7f53ac 0%, #647dee 74%)",
        background: "linear-gradient(315deg, #7f53ac 0%, #647dee 74%)",
        boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    }
    const navtxt = {
        fontSize: "14px",
        fontWeight: "400",
        marginRight: "4px",
    }

    const additionalStyles = {
        marginTop: 4,
        fontSize: 14
    };

    const combinedStyles = { ...navtxt, ...additionalStyles };


    const logoutApp = () => {
        sessionStorage.removeItem("servicePanel");
        dispatch(serviceLogout());
        navigate("/service");
    }


    const getNotificationCount = async () => {
        await axios.get(getServiceFormAdminFlagDataCountAPIURL)
            .then((res) => {
                // console.log(res.data)

                if (res.data !== null) {
                    // console.log(res.data)
                    if (res.data !== 0) {
                        play();
                    }
                    dispatch(userNotification({ notificationCount: res.data }));
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getNotificationCount();
    }, [])

    //Auto Logout after 1 hr Start    

    const [counter, setCounter] = useState(0)

    var timer;
    const autoNotificationCall = () => {
        setCounter(counter + 1);
        getNotificationCount();
    }

    const resetTimer = () => {
        timer = 0;
        clearTimeout(timer);
        // console.log(timer);
        timer = setTimeout(autoNotificationCall, 1000 * 60 * 60); //1 hr
    }

    useEffect(() => {
        resetTimer();

    }, [counter])

    //Auto Logout after 1 hr End


    return (
        <>
            <div>
                <Navbar collapseOnSelect expand="lg" bg="light" variant="dark" style={navbg}>
                    <Container>
                        <Navbar.Brand>
                            <img src={TechnoAarvLogo} width="240px" alt="" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ color: "#fff", border: "1px solid #fff" }} >
                            <MenuIcon fontSize='medium' />
                        </Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto"></Nav>
                            <Nav>
                                <Nav.Link style={navtxt} href="#/Service/s001/d001">Dashboard</Nav.Link>

                                <NavDropdown title="Master" id="collasible-nav-dropdown" style={combinedStyles}>
                                    <NavDropdown.Item href="#/Service/s001/m001">Module Master</NavDropdown.Item>
                                    <NavDropdown.Item href="#/Service/s001/m002">Form Master</NavDropdown.Item>
                                    <NavDropdown.Item href="#/Service/s001/m003">Toggle Master</NavDropdown.Item>
                                </NavDropdown>

                                <Nav.Link style={navtxt} href="#/Service/s001/su001">Alert</Nav.Link>
                                <Nav.Link style={navtxt} onClick={handleShow}>Form Processing</Nav.Link>
                                <Nav.Link style={navtxt} href="#/Service/s001/st001">Toggle To User</Nav.Link>
                                <Nav.Link style={navtxt} className="fw-bold" onClick={() => logoutApp()}>Logout <ExitToAppIcon fontSize='small' /></Nav.Link>

                            </Nav>
                        </Navbar.Collapse>
                        <Navbar.Brand>
                            <button type="button" className="btn btn-light position-relative p-1" onClick={play}>
                                <NotificationsActiveIcon fontSize="small" className='text-primary' />
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    {authUser.notificationCount}
                                </span>
                            </button>
                        </Navbar.Brand>
                    </Container>
                </Navbar>

            </div>
            <FormProcessingModal showModal={showModal} setShowModal={setShowModal} authUser={authUser} />
        </>

    )
}

export default ServiceHeader