import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { GetClassWithDivData } from '../../Academic/Services/MonthlyLessonPlanAPIURL';
import { EmployeeMasterBranchWiseGetData } from '../../CommonServices/EmployeeMasterAPIURL';
import { printStudentIdcardClassDivWise, printStudentIdcardClassDivWiseExcelReport, printStudentIdcardRegNo, printStudentIdcardRegNoExcelReport } from '../Services/StudentIdcardPrintingAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { getStudentList } from '../../FeeManagement/Services/FeeReceiptAPIURL';
import CircularProgress from '@mui/material/CircularProgress';
import { printEmployeeIdCardReportAPIURL } from '../Services/StudentIdcardAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const IdCardPrintingReport = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "ID Card Printing";
    const classes = useStyles();

    const [view, setView] = useState(1);
    const [loaderOption, setLoaderOption] = useState(false);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);
    const loading = open && options.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...studentData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const [option, setOption] = useState(1);
    const [type, setType] = useState(1);
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [employeeData, setEmployeeData] = useState([]);
    const [employeeObj, setEmployeeObj] = useState(null);
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);

    const [page, setPage] = useState('');

    useEffect(() => {
        if (authUser.deptId !== null && authUser.branchId !== null) {
            getStudentData();
        }
    }, [numCal, authUser])


    const getStudentData = async () => {
        if (offset === 0) {
            setLoaderOption(true)
        }
        // console.log(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
        await axios.get(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
            .then((res) => {
                if (res.data !== null) {
                    const tempData = res.data;
                    //console.log(tempData.length)
                    setStudentData(studentData.concat(tempData));
                    setLoaderOption(false)
                    //console.log("Data : " + JSON.stringify(data))
                    // console.log("____________________________________________")
                    if (tempData.length === limits) {
                        setOffset(offset + limits);
                        setNumCall(numCal + 1);
                        //console.log("Offset ===============" + offset);
                    }
                }
            })
    }

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        if (authUser.branchId != null) {
            await axios.get(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`)
                .then(res => {
                    setEmployeeData(res.data);
                })
        }

        if (authUser.branchId != null && authUser.deptId != null) {
            await axios.get(`${GetClassWithDivData}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivData(res.data);
                })
        }
    }

    const getStudentIdByClassDiv = async () => {
        setLoaderOption(true);
        console.log(`${printStudentIdcardClassDivWise}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&pageType=${page}`)
        await axios.get(`${printStudentIdcardClassDivWise}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&pageType=${page}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found");
                }
                else {
                    setLoaderOption(false);
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                }
            }).catch(err => {
                console.log("Student Id Card class-division wise" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const getStudentIdByRegNo = async () => {
        setLoaderOption(true);
        await axios.get(`${printStudentIdcardRegNo}?langId=${authUser.branchMedium}&regNo=${studentObj.regCode}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&pageType=${page}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found");
                }
                else {
                    setLoaderOption(false);
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                }
            }).catch(err => {
                console.log("Student Id Card student wise" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const getStudentIdByClassDivExcel = async () => {
        setLoaderOption(true);
        console.log(`${printStudentIdcardClassDivWiseExcelReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&excel=${view}`)
        await axios.get(`${printStudentIdcardClassDivWiseExcelReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&excel=${view}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found");
                }
                else {
                    setLoaderOption(false);
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                }
            }).catch(err => {
                console.log("Student Id Card class-division wise" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const getStudentIdByRegNoExcel = async () => {
        setLoaderOption(true);
        await axios.get(`${printStudentIdcardRegNoExcelReport}?langId=${authUser.branchMedium}&regNo=${studentObj.regCode}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&excel=${view}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found");
                }
                else {
                    setLoaderOption(false);
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                }
            }).catch(err => {
                console.log("Student Id Card student wise" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const getEmployeeIdCard = async () => {
        setLoaderOption(true);
        console.log(`${printEmployeeIdCardReportAPIURL}?langId=${authUser.branchMedium}&empId=${employeeObj.employeeId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&pageType=${page}`)
        await axios.get(`${printEmployeeIdCardReportAPIURL}?langId=${authUser.branchMedium}&empId=${employeeObj.employeeId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&pageType=${page}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else {
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                    setLoaderOption(false);
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Employee id card printing" + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const getIdCardReport = async (e) => {
        e.preventDefault();
        if (option == 1) {
            getEmployeeIdCard();
        }
        else if (option == 2) {
            if (view == 1) {
                if (type == 1) {
                    getStudentIdByClassDiv();
                }
                else if (type == 2) {
                    getStudentIdByRegNo();
                }
            }
            else if (view == 2) {
                if (type == 1) {
                    getStudentIdByClassDivExcel();
                }
                else if (type == 2) {
                    getStudentIdByRegNoExcel();
                }
            }
        }
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={getIdCardReport}>
                                <div className='row'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={option}
                                                label="Select Option"
                                                onChange={(e) => {
                                                    setOption(e.target.value);
                                                    setType(1);
                                                    setEmployeeObj(null);
                                                    setStudentObj(null);
                                                    setClassDivObj(null);
                                                    setPage('');
                                                    setView(1);
                                                }}
                                            >
                                                <MenuItem value={1}>Employee</MenuItem>
                                                <MenuItem value={2}>Student</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>



                                {(() => {
                                    if (option == 1) {
                                        return (
                                            <>
                                                <div className='row my-2'>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Employee :</label>
                                                    <div className='col-sm-4'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            //disableClearable
                                                            options={employeeData}
                                                            style={{ fontSize: '14px' }}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, item) => {
                                                                setEmployeeObj(item);
                                                                setType(1);
                                                                setStudentObj(null);
                                                                setClassDivObj(null);
                                                                setPage('');
                                                                setView(1);
                                                            }}
                                                            value={employeeObj}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Employee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    else if (option == 2) {
                                        return (
                                            <>
                                                <div className="row my-2">
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>View :</label>
                                                    <div className='col-sm-3'>
                                                        <FormControl fullWidth margin='dense'>
                                                            <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>View</InputLabel>
                                                            <Select
                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                size='small'
                                                                required
                                                                fullWidth

                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={view}
                                                                label="View"
                                                                onChange={(e) => {
                                                                    setView(e.target.value);
                                                                    setType(1);
                                                                    setStudentObj(null);
                                                                    setClassDivObj(null);
                                                                    setPage('');
                                                                }}
                                                            >
                                                                <MenuItem value={1}>PDF</MenuItem>
                                                                <MenuItem value={2}>Excel</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                <div className='row my-2'>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Sub-option :</label>
                                                    <div className='col-sm-3'>
                                                        <FormControl fullWidth margin='dense'>
                                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Sub-option</InputLabel>
                                                            <Select
                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                size='small'
                                                                required
                                                                fullWidth
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={type}
                                                                label="Select Sub-option"
                                                                onChange={(e) => {
                                                                    setType(e.target.value);
                                                                    setStudentObj(null);
                                                                    setClassDivObj(null);
                                                                    setPage('');
                                                                }}
                                                            >
                                                                <MenuItem value={1}>Class-Division Wise</MenuItem>
                                                                <MenuItem value={2}>Student Wise</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                {(() => {
                                                    if (type == 1) {
                                                        return (
                                                            <>
                                                                <div className='row my-2'>
                                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division:</label>
                                                                    <div className='col-sm-3'>
                                                                        <Autocomplete
                                                                            id="combo-box-demo"
                                                                            options={classDivData}
                                                                            style={{ fontSize: '14px' }}
                                                                            PaperComponent={({ children }) => (
                                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                            )}
                                                                            onChange={(event, item) => {
                                                                                setClassDivObj(item);
                                                                                setStudentObj(null);
                                                                                setPage('');
                                                                            }}
                                                                            value={classDivObj}
                                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? (option.classNameMr + " " + option.divisionNameMr) : (option.classNameEn + " " + option.divisionNameEn)}
                                                                            size="small"
                                                                            renderInput={params => (
                                                                                <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Class-division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (type == 2) {
                                                        return (
                                                            <>
                                                                <div className='row my-2'>
                                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Student :</label>
                                                                    <div className='col-sm-4'>
                                                                        {/* <Autocomplete
                                                                            id="combo-box-demo"
                                                                            open={open}
                                                                            onOpen={() => {
                                                                                setOpen(true);
                                                                            }}
                                                                            onClose={() => {
                                                                                setOpen(false);
                                                                            }}
                                                                            loading={loading}
                                                                            options={studentData}
                                                                            PaperComponent={({ children }) => (
                                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                            )}
                                                                            onChange={(event, newValue) => {
                                                                                setStudentObj(newValue);
                                                                                setPage('');
                                                                            }}
                                                                            onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                                                            value={studentObj}
                                                                            style={{ fontSize: '14px' }}
                                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                                                            renderOption={(props, option) => (
                                                                                <div className='' {...props}>
                                                                                    <div className=''>
                                                                                        {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                                                                                    </div>

                                                                                </div>
                                                                            )}
                                                                            size="small"
                                                                            renderInput={params => (
                                                                                <TextField {...params}
                                                                                    InputProps={{
                                                                                        ...params.InputProps,
                                                                                        endAdornment: (
                                                                                            <React.Fragment>
                                                                                                {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                                                {params.InputProps.endAdornment}
                                                                                            </React.Fragment>
                                                                                        ),
                                                                                    }}
                                                                                    margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                                    required />
                                                                            )}
                                                                        /> */}
                                                                        <Autocomplete
                                                                            id="combo-box-demo"
                                                                            options={studentData}
                                                                            style={{ fontSize: '14px' }}
                                                                            PaperComponent={({ children }) => (
                                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                            )}
                                                                            onChange={(event, newValue) => {
                                                                                setStudentObj(newValue);
                                                                                setPage('');
                                                                            }}
                                                                            value={studentObj}
                                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                                                            size="small"
                                                                            renderInput={params => (
                                                                                <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                })()}
                                            </>
                                        )
                                    }
                                })()}

                                <div className='row my-2' hidden={(view == 2 && option == 2) ? true : false}>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>ID Card Layout :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select ID Card Layout</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required={(view == 2 && option == 2) ? false : true}
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={page}
                                                label="Select ID Card Layout"
                                                onChange={(e) => {
                                                    setPage(e.target.value);

                                                }}
                                            >

                                                <MenuItem value={1}>Card Landscape</MenuItem>
                                                <MenuItem value={2}>Card Portrait</MenuItem>
                                                <MenuItem value={3}>A4 Landscape</MenuItem>
                                                <MenuItem value={4}>A4 Portrait</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default IdCardPrintingReport