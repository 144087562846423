import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel'
import { Autocomplete, Checkbox, Paper, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import { getUserRolesAPIURL } from '../../Sanstha/Services/SansthaAPIURL';
import { getToggleToRoleAllocAPIURL, getToggleToRoleBranchWiseAPIURL, saveToggleToRoleBranchWiseAPIURL } from '../Service/ToggleToRoleAPIURL';
import Loader from '../../CommonComponent/Loader';
import { toast } from 'react-toastify';

const ToggleToRoleBranchWise = () => {
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const [branchObj, setBrancjObj] = useState(null);
    const [branchData, setBranchData] = useState([]);
    const [roleObj, setRoleObj] = useState(null);
    const [roleData, setRoleData] = useState([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [tempData, setTempData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [checkValue, setCheckValue] = useState([]);
    const [Val, setVal] = useState(false);
    const [chkRegNum, setChkRegNum] = useState('');


    const handleCheckChange = (val, items) => {
        setVal(val);

        const SaveObjectData = {
            "toggleName": items.toggleName,
            "branchId": items.branchId,
            "roleId": items.roleId,
            "toggleId": items.toggleId
        }
        if (val == true) {
            setCheckValue(checkValue.concat(SaveObjectData));

            //if checkbox is checked
            let updatedData = data;
            data.map((e, index) => {
                if (e.toggleId == SaveObjectData.toggleId) {
                    updatedData[index] = ({ ...e, isAllocated: 1 })
                }

            })
            let checkdata = updatedData.filter(e => e.isAllocated === 0)
            //console.log(checkdata.length);
            //console.log("Checkvalue=" + (checkValue.length + 1) + "------" + "filteredData=" + filteredData.length)
            // if (checkdata.length === 0) {
            //     setCheckAllSub(true);
            // }

            // console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setData(updatedData);
                setFilteredData(updatedData)
            }
        }
        else if (val == false) {
            const removedData = checkValue.filter((item) => item.toggleId != items.toggleId)
            setCheckValue(removedData);
            // setCheckAllSub(false);

            //if checkbox is unChecked
            let updatedData = data;
            data.map((e, index) => {
                if (e.toggleId == SaveObjectData.toggleId) {
                    updatedData[index] = ({ ...e, isAllocated: 0 })
                }

            })
            // console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setData(updatedData);
                setFilteredData(updatedData);
            }

        }
        setChkRegNum(SaveObjectData.toggleId);
    };

    // useEffect(() => {
    //     if (Val == true) {
    //         let updatedDataTrue = data
    //         data.map((e, index) => {
    //             if (e.regNo == chkRegNum) {
    //                 updatedDataTrue[index] = ({ ...e, isAllocated: 1 })
    //             }
    //         })
    //         if (searchTerm == "") {
    //             setChkRegNum('');
    //             setData(updatedDataTrue);
    //             setFilteredData(updatedDataTrue);
    //         }
    //     }
    //     else if (Val == false) {
    //         //console.log(checkDivId)
    //         let updatedDataFalse = data
    //         data.map((e, index) => {
    //             if (e.regNo == chkRegNum) {
    //                 updatedDataFalse[index] = ({ ...e, isAllocated: 0 })
    //             }
    //         })

    //         //console.log(JSON.stringify(updatedDataFalse))
    //         if (searchTerm == "") {
    //             setChkRegNum('');
    //             setData(updatedDataFalse);
    //             setFilteredData(updatedDataFalse);
    //         }
    //     }


    //     // if (checkAllSub == false && Val == false) {
    //     //     let updatedDataFalse = data
    //     //     data.map((e, index) => {
    //     //         if (e.regNo == chkRegNum) {
    //     //             updatedDataFalse[index] = ({ ...e, isAllocated: 0 })
    //     //         }
    //     //     })
    //     //     if (searchTerm == "") {
    //     //         setData(updatedDataFalse);
    //     //         setFilteredData(updatedDataFalse);
    //     //     }
    //     // }
    //     // else if (checkAllSub == true) {
    //     //     let updatedDataTrue = data
    //     //     data.map((e, index) => {
    //     //         //if (e.divisionId == checkDivId) {
    //     //         updatedDataTrue[index] = ({ ...e, isAllocated: 1 })
    //     //         //}
    //     //     })
    //     //     if (searchTerm == "") {
    //     //         setChkRegNum('');
    //     //         setData(updatedDataTrue);
    //     //         setFilteredData(updatedDataTrue);
    //     //     }
    //     // }
    // }, [searchTerm, Val, chkRegNum])


    const getBranchData = async () => {
        setLoaderOption(true);

        await axios.get(BaseURLInstituteMaster)
            .then((response) => {
                if (response.data !== null) {

                    let newData = response.data;
                    let updatedData = [{ "id": 0, "schoolNameEn": "All", "schoolNameMr": "All" }, ...newData]
                    setBranchData(newData);
                    setLoaderOption(false);
                    // console.log(JSON.stringify(updatedData))
                }
            })
            .catch((error) => { console.log(error); setLoaderOption(false); })

        await axios.get(getUserRolesAPIURL)
            .then((response) => {
                if (response.data !== null) {
                    let newData = response.data;
                    // let updatedData = [{ "id": 0, "name": "All" }, ...newData]
                    setRoleData(newData);
                    setLoaderOption(false);
                    //console.log(JSON.stringify(updatedData))
                }
            })
            .catch((error) => {
                console.log(error);
                setLoaderOption(false);
            })
    }

    useEffect(() => {

        getBranchData();

    }, [authUser])

    const getToggleDetails = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        await axios.get(`${getToggleToRoleBranchWiseAPIURL}?roleId=${roleObj.id}&bid=${branchObj.id}`)
            .then((res) => {
                if (res.data !== null) {
                    console.log(JSON.stringify(res.data))
                    setData(res.data);
                    setFilteredData(res.data);
                    setTempData(res.data);
                    setLoaderOption(false);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const FormTableHeading = [{ label: "Toggle", key: "toggleNamee" }, { label: "Status", key: "status" }]

    // useEffect(() => {
    //     setFilteredData([])
    //     let tempFilteredData =
    //         classData.filter(
    //             (item) =>
    //                 item.classNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //                 item.classNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //                 item.divisionNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //                 item.divisionNameMr.toLowerCase().includes(searchTerm.toLowerCase())
    //         )
    //     setFilteredData(tempFilteredData);
    // }, [searchTerm]);

    const saveToggleToRule = async () => {
        setLoaderOption(true);
        const newData = data.filter(e => e.isAllocated == 1)

      
            console.log(JSON.stringify(newData))
            await axios.post(`${saveToggleToRoleBranchWiseAPIURL}?roleId=${roleObj.id}&bid=${branchObj.id}`, newData)
                .then(res => {
                    if (res.data == "SAVED") {
                        setLoaderOption(false);
                        setData([]);
                        setFilteredData([]);
                        toast.success("Saved successfully.")
                    }
                    else {
                        setLoaderOption(false);
                        console.log("save failed")
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log(err);
                    toast.error("Something went wrong, please check.")
                })
       
    }



    if (loaderOption == true) {
        return (
            <Loader />
        )
    }
    else {
        return (

            <>
                <TitleLabel titleId="Toggle To User" />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 20px #ABB2B9", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={getToggleDetails}>
                        <div className="row">
                            <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Branch :</label>
                            <div className='col-sm-5'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={branchData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        setBrancjObj(newValue);
                                        setData([]);
                                        setFilteredData([]);
                                    }}
                                    value={branchObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.schoolNameMr}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Branch" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>User :</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    value={roleObj}
                                    id="combo-box-demo"
                                    options={roleData}
                                    onChange={(event, newValue) => {
                                        setRoleObj(newValue);
                                        setData([]);
                                        setFilteredData([]);
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.name}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select User" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-sm-4 offset-sm-1">
                                <button type='submit' className='btn btn-sm btn-warning'>View</button>
                            </div>
                        </div>
                    </form>

                    {
                        (data.length != 0)
                            ?
                            <div className='row'>
                                {/* <div className='row my-1'>
                                    <div className='offset-sm-4 mt-1'>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "left", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            value={searchTerm}
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                        />
                                    </div>
                                </div> */}
                                <div className='table-responsive col-sm-6 offset-sm-1 mt-4' style={{ maxHeight: "330px" }} >
                                    <table className="table table-bordered">
                                        <thead className="" style={{ position: "sticky", zIndex: "1", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                            <tr>
                                                {FormTableHeading.map((item, index) => {
                                                    return (
                                                        <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col" key={index}>{item.label}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                            {filteredData.map((item, index) => {
                                                return (
                                                    <tr key={index} className="table-sm">
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.toggleName}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                            <Checkbox defaultChecked={(item.isAllocated == 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                onChange={(e) => {
                                                                    let val = e.target.checked;
                                                                    handleCheckChange(val, item);
                                                                }
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>


                                <div className="row mt-3">
                                    <div className="offset-sm-1">
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => saveToggleToRule()}>Save</button>
                                        <button type="button" onClick={() => { setFilteredData([]); setData([]); }} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                </div>
            </>
        )
    }

}

export default ToggleToRoleBranchWise
