import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "../../../CommonServices/APIURL";

//EndowmentList APIURL
//localhost:8080/institute/sss/endowment/reports/getEndowmentDataReport
export const printEndowmentReportAPIURL = BaseURL + "endowment/reports/getEndowmentDataReport";

//Endowment Transaction Report APIURL
//For Sanstha
//localhost:8080/institute/sss/endowment/reports/getEndowmentDataDetailReport
export const printEndowmentWithConditionReport = BaseURL + "endowment/reports/getEndowmentDataDetailReport";

//For School
//http://192.168.195.102:8081/institute-sss/institute/sss/endowment/reports/getEndowmentDataDetailReportBySectionIdAndBranchId?sid=3&bid=1
export const printEndowmentWithConditionForSchoolReport = BaseURL + "endowment/reports/getEndowmentDataDetailReportBySectionIdAndBranchId";

//For Sanstha
//localhost:8080/institute/sss/endowment/reports/getEndowmentDataDetailReportByPrizeCode?prizeCode=5
export const printEndowmentByPrizeCodeAPIURL = BaseURL + "endowment/reports/getEndowmentDataDetailReportByPrizeCode";

//For School
//http://192.168.195.102:8081/institute-sss/institute/sss/endowment/reports/getEndowmentDataDetailReportByPrizeCodeAndSectionIdAndBranchId?prizeCode=648&sid=3&bid=1
export const printEndowmentByPrizeCodeSchoolAPIURL = BaseURL + "endowment/reports/getEndowmentDataDetailReportByPrizeCodeAndSectionIdAndBranchId";



//Endowment Interest report APIURL
//localhost:8080/institute/sss/endowment/reports/getPrizeDistributionSummaryYearWise?sessionYear=2022
export const getEndowmentInterestReportAPIURL = BaseURL + "endowment/reports/getPrizeDistributionSummaryYearWise";

//localhost:8080/institute/sss/endowment/reports/getPrizeDistributionSummaryYearAndPrizeCodeWise?sessionYear=2022&prizeCode=2
export const getEndowmentInterestPrizeCodeWiseReport = BaseURL + "endowment/reports/getPrizeDistributionSummaryYearAndPrizeCodeWise";

//Sanstha award blank APIURL
//localhost:8080/institute/sss/endowment/reports/getEndowmentPrizeList?sessionYear=2022
export const sansthaAwardBlankEndowmentListAPIURL = BaseURL + "endowment/reports/getEndowmentPrizeList";

//sanstha award distribution summary APIURL
//localhost:8080/institute/sss/endowment/reports/getSansthaAwardDistributionSummary?sessionYear=2022
export const getSansthaAwardDistSummaryAllAPIURL = BaseURL + "endowment/reports/getSansthaAwardDistributionSummary";

//localhost:8080/institute/sss/endowment/reports/getSansthaAwardDistributionSummaryByEndowmentId?sessionYear=2022&endowmentId=1
export const getSansthaAwardDistSummarySchoolAPIURL = BaseURL + "endowment/reports/getSansthaAwardDistributionSummaryByEndowmentId";

//School Wise Endowment Report APIURL
//localhost:8080/institute/sss/endowment/reports/getAllSchoolEndowmentSummary?sessionYear=2022
export const printAllSchoolWiseEndowmentAPIURL = BaseURL + "endowment/reports/getAllSchoolEndowmentSummary";

//localhost:8080/institute/sss/endowment/reports/getSchoolWiseEndowmentSummary?sessionYear=2022&endowmentId=1
export const printSchoolWiseEndowmentAPIURL = BaseURL + "endowment/reports/getSchoolWiseEndowmentSummary";


//ClassWise Endowment Report
//localhost:8080/institute/sss/endowment/reports/getClassWiseEndowmentPrizeDistributionList?sessionYear=2022&classId=4
export const printClassWiseEndowmentReportAPIURL = BaseURL + "endowment/reports/getClassWiseEndowmentPrizeDistributionList";

//getClassWiseEndowmentPrizeDistributionListBySectionIdAndBranchId
export const printClassWiseSchoolEndowmentReportAPIURL = BaseURL + "endowment/reports/getClassWiseEndowmentPrizeDistributionListBySectionIdAndBranchId";


//SubjectWise Endowment report
//localhost:8080/institute/sss/endowment/reports/getSubjectWiseEndowmentPrizeDistributionList?sessionYear=2022&subId=1
export const printSubWiseEndowmentReportAPIURL = BaseURL + "endowment/reports/getSubjectWiseEndowmentPrizeDistributionList";

//getSubjectWiseEndowmentPrizeDistributionListBySectionIdAndBranchId
export const printSubWiseSchoolEndowmentReportAPIURL = BaseURL + "endowment/reports/getSubjectWiseEndowmentPrizeDistributionListBySectionIdAndBranchId";


//genderWise Endowment report
//localhost:8080/institute/sss/endowment/reports/getGenderWiseEndowmentPrizeDistributionList?sessionYear=2022&genderId=3
export const printGenderWiseEndowmentreportAPIURL = BaseURL + "endowment/reports/getGenderWiseEndowmentPrizeDistributionList";

//getGenderWiseEndowmentPrizeDistributionListBySectionIdAndBranchId
export const printGenderWiseSchoolEndowmentreportAPIURL = BaseURL + "endowment/reports/getGenderWiseEndowmentPrizeDistributionListBySectionIdAndBranchId";




//Endowment Register APIURL
//localhost:8080/institute/sss/endowment/reports/endowmentRegisterPrinting
export const printEndowmentRegister = BaseURL + "endowment/reports/endowmentRegisterPrinting";

//Schoolwise endowmentwise detail report
//localhost:8080/institute/sss/endowment/reports/getAllSchoolEndowmentDetailReport?sessionYear=2022
export const printAllSchoolWiseEndowmentWiseReport = BaseURL + "endowment/reports/getAllSchoolEndowmentDetailReport";

//getSchoolWiseEndowmentDetailReportBySectionIdAndBranchId
export const printSchoolWiseEndowmentWiseReportForSchool = BaseURL + "endowment/reports/getSchoolWiseEndowmentDetailReportBySectionIdAndBranchId";



//localhost:8080/institute/sss/endowment/reports/getSchoolWiseEndowmentDetailReport?sessionYear=2022&endowmentId=1
export const printSchoolWiseEndowmentWiseReport = BaseURL + "endowment/reports/getSchoolWiseEndowmentDetailReport";